.sidebar-section {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: 8px 0px;
  padding: 0;
  color: #f3f3f3;
  flex-direction: column;
}

.sidebar-section-title {
  background-color: #202020;
  margin: 0 0 8px 0;
  padding: 0;
  color: #eeeeee;
  line-height: 2.6rem;
  padding-left: 24px;
}



.sidebar-section-body hr{
  width: 100%;
  margin: 5px 0px;
  border-color: #ececec;
  border-style: solid;
  border-width: 1px;
}

.sidebar-section-body {
  width: 100%;
  display: flex;
  padding: 4px 24px;
  box-sizing: border-box;
  color: #ebebeb;
  flex-direction: column;
}

.sidebar-section-body p{
  margin: 0;
}

.sidebar-section-body h1,
.sidebar-section-body h2,
.sidebar-section-body h4,
.sidebar-section-body h5 {
  margin: 6px 0;
}

.sidebar-section-body h1 {
  font-size: 2.5rem;
}

.sidebar-section-body a {
  text-decoration: none;
  color: #f3f3f3;
}
