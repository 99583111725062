.skill-container {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  color: white;
}

.skill-container p {
  margin: 0px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

h5.skill-level {
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
