
.skillbar-container {
  display:flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
}
.skillbar {
  content: '';
  width: 100%;
  background-color: #2e89ff;
  height: 10px;
  margin: 0;
  padding: 0;
	border-radius: 5px;
}

.skillbar-negative {
  content: '';
  width: 100%;
  background-color: #1a1a1a;
  height: 10px;
  margin: 0;
  padding: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}