.sidebar-icons {
  padding: 8px 16px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -24px;
}

.demo-icon {
  content: '';
  height: 20px;
  width: 20px;
  background-color: rgb(112, 87, 145);
  border-radius: 4px;
  margin: 4px;
}