

.resume-container {
  max-width: 860px;
  margin: 32px auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.mobile-message {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  text-align: center;
}

.printable.resume-container {
  position: absolute;
  left: -10000px;
}

@media (max-width: 890px) {
  .banner-button {
    display: none;
  }
  .mobile-message {
    display: flex;
  }
  .online.resume-container {
    display: none;
  }
}
