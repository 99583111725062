.tech-icon {
  height: 36px;
  max-height: 36px;
  margin-right: 6px;
  margin-top: 6px;
  padding: 2px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: none;
  outline: none;
}

.tech-icon img {
  height: 36px;
  width: auto;
}

.tech-icon img:hover {
  filter: drop-shadow(0px 1px 2px rgba(var(--text-color-shade), 0.3));
}

.tooltip-text {
  margin: 0;
  text-transform: capitalize;
}