.ProfileDisplay {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px 25px 0 25px;
  box-sizing: border-box;
}

.ImageContainer {
  height: 126px;
  width: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px solid #2491ff;
}

.ProfileImage {
  content: '';
  height: 120px;
  width: 120px;
  background-color: rgb(112, 87, 145);
  border-radius: 50%;
  margin: 0 auto;
}
