.sidebar {
  height: 1216px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  box-sizing: border-box;
  background-color: #2b2b2b;
}