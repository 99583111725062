.skill {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.skillbar-container {
  display:flex;
  flex-direction: column;
  height: 100%;
	width: 20px;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
}
.skillbar {
  content: '';
  height: 100%;
  background-color: #2e89ff;
  width: 20px;
  margin: 0;
  padding: 0;
	border-radius: 10px;
}

.skillbar-negative {
  content: '';
  height: 100%;
  background-color: #1a1a1a;
  width: 20px;
  margin: 0;
  padding: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.Label {
	margin: 0;
	margin-top: 3px;
	font-weight: 600;
	font-size: 12px;
	text-transform: capitalize;
}