.SkillbarOuter {
  box-sizing: border-box;
  padding: 2px;
  border-radius: 4px;
  height: 16px;
  width: 100%;
}

.SkillbarInner {
  left: 0;
  height: 100%;
  background-color: #2491ff;
  border-radius: 2px;
  content: '';
}